/* eslint-disable eqeqeq */
import {  OverlayTrigger, Stack } from "react-bootstrap";
import i18n from "../../i18n";
import { ReadFriendlyNumber } from "../../lib/utils";
import StyledAccordion from "../Accordion/StyledAccordion";
import Datalist, { ListColumn } from "../Datalist/Datalist";
import {
  KickbackChanged,
  KickbackChangeState,
  ProjectReport,
} from "../modal/SubmitKickbackProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import React from "react";

type Test = Pick<KickbackChanged, "changesComment" | "changedSum">;
type BLa = Pick<ProjectReport, "commentRowChange" | "sumExclMomsChange">;
type CheckBox = {
  [key: string]: boolean;
};
interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
  kickbackChangesReport: KickbackChangeState;
  renderKickbackChangesField: (item: KickbackChanged,keyForProp: keyof Test) => JSX.Element;
  contractConfirmation: CheckBox;
  checkBoxMenu: (contractId: string) => JSX.Element;
  reports: any;
  loading: boolean;
  chageForChangeChange: (event: any,item: ProjectReport,propKey: keyof BLa) => void;
  rowEdit?:boolean
  showValidationInfo: (propsa: any, key: string) => JSX.Element;
 // editModeToggleHandler:()=>void
}
const editColumns = ({ ...props }: Props): ListColumn<ProjectReport>[] => {
  const buffs: ListColumn<ProjectReport>[] = [
    {
      fieldName: "projectName",
      isActive: true,
      key: "projectName",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.projectName"),
    },
    {
      fieldName: "invoiceReference",
      isActive: true,
      key: "invoiceReference",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.invoiceReference"),
    },
    {
      fieldName: "invoiceIdentifier",
      isActive: true,
      key: "invoiceIdentifier",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.invoiceLabel"),
    },

    {
      fieldName: "comment",
      isActive: true,
      key: "comment",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.comment"),
    },
    {
      fieldName: "sumExclMoms",
      key: "sumExclMoms",
      minWidth: 40,
      maxWidth: 120,
      isActive: true,
      name: `${i18n.t("kickbackProject.sum")}`,
      onRender(item, col) {
        return item.sumExclMoms.toFixed(2);
      },
    },
    {
      fieldName: "sumExclMomsChangeField",
      isActive: true,
      key: "sumExclMomsChangeField",
      minWidth: 60,
      customCellPadding: "1px",
      name: i18n.t("kickback.table.header.sumExclMoms"),
      onRender(item, col) {
        return editCell(item, "sumExclMomsChange", props); //item.sumExclMomsChangeField
      },
    },
    {
      fieldName: "sum",
      isActive: true,
      key: "commentRowChangeField",
      minWidth: 60,
      customCellPadding: "1px",
      name: i18n.t("kickback.table.header.CommentChanges"),

      onRender(item, col) {
        return editCell(item, "commentRowChange", props); //item.commentRowChangeField
      },
    },
    {
      fieldName: "kickbackSum",
      isActive: false,
      key: "kickbackSum",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t("kickbackProject.kickBackToBill"),
    },

    {
      fieldName: "projectEndDate",
      isActive: true,
      key: "projectEndDate",
      minWidth: 40,
      maxWidth: 120,
      name: i18n.t(
        "kickbackProject.endDate"
      ) /*this.props.t("invoiceReportList.reportDate").toString()*/,
      onRender(item, col) {
        // const date = new Date()
        return item.projectEndDate == null
          ? ""
          : new Date(item.projectEndDate).toLocaleDateString();
      },
    },
  ];
  return buffs;
};

// const columns: ListColumn<ProjectReport>[] = [
//   {
//     fieldName: "projectName",
//     isActive: true,
//     key: "projectName",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t("kickbackProject.projectName"),
//   },
//   {
//     fieldName: "invoiceReference",
//     isActive: true,
//     key: "invoiceReference",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t("kickbackProject.invoiceReference"),
//   },
//   {
//     fieldName: "invoiceIdentifier",
//     isActive: true,
//     key: "invoiceIdentifier",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t("kickbackProject.invoiceLabel"),
//   },

//   {
//     fieldName: "comment",
//     isActive: true,
//     key: "comment",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t("kickbackProject.comment"),
//   },
//   {
//     fieldName: "sumExclMoms",
//     key: "sumExclMoms",
//     minWidth: 40,
//     maxWidth: 120,
//     isActive: true,
//     name: `${i18n.t("kickbackProject.sum")}`,
//     onRender(item, col) {
//       return item.sumExclMoms.toFixed(2);
//     },
//   },

//   {
//     fieldName: "kickbackSum",
//     isActive: false,
//     key: "kickbackSum",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t("kickbackProject.kickBackToBill"),
//   },

//   {
//     fieldName: "projectEndDate",
//     isActive: true,
//     key: "projectEndDate",
//     minWidth: 40,
//     maxWidth: 120,
//     name: i18n.t(
//       "kickbackProject.endDate"
//     ) /*this.props.t("invoiceReportList.reportDate").toString()*/,
//     onRender(item, col) {
//       // const date = new Date()
//       return item.projectEndDate == null
//         ? ""
//         : new Date(item.projectEndDate).toLocaleDateString();
//     },
//   },
// ];
export const SupplierChanges = (props: Props):JSX.Element => {
  
 
  if (!props.rowEdit /*|| typeof props.rowEdit == 'undefined'*/) {
    return compView(props)//renderCompressed({ ...props });
  } else {
    return renderMultipleChangesRow(props);
  }
};
const editCell = (
  item: ProjectReport,
  keyForProp: keyof BLa,
  { ...props }: Props
) => {
  if (keyForProp == "commentRowChange") {
    // const boolllllll = item.sumTotal !== item.changedSum && item.changesComment.length == 0
    const boolllllll =
      item.sumExclMoms !== item.sumExclMomsChange &&
      item.commentRowChange.length == 0;
    return (
      <div className={boolllllll ? "fieldWrapper  fieldWrapper-error" : ""}>
        <input
          spellCheck={false}
          type={"text"}
          style={{ height: "100%",
            border: "2px solid rgb(245, 156, 47,0.2)"
          }
          }
          value={item[keyForProp]}
          onChange={
            (event) =>
              props.chageForChangeChange(
                event,
                item,
                keyForProp
              ) /*this.handleKickbackFieldChangeTest(item,keyForProp,event.target.value)*/
          }
        />
        <OverlayTrigger
          placement="auto-start"
          delay={{ show: 250, hide: 400 }}
          overlay={(propsa) =>
            props.showValidationInfo(propsa, "kommentarÄndringar")
          }
        >
          {boolllllll ? (
            <FontAwesomeIcon
              className="exclamationIconStyle"
              icon={faExclamation}
            />
          ) : (
            <></>
          )}
        </OverlayTrigger>
      </div>
    );
  } else {
    return (
      <input
        style={{
          MozAppearance: "textfield",
          margin: 0,
          WebkitAppearance: "none",
          height: "100%",
          border: "2px solid rgb(245, 156, 47,0.2)",
        }}
        type={"number"}
        value={item[keyForProp]}
        onChange={
          (event) =>
            props.chageForChangeChange(
              event,
              item,
              keyForProp
            ) /*this.handleKickbackFieldChangeTest(item,keyForProp,+event.target.value)*/
        }
      />
    );
  }
};

const renderMultipleChangesRow = (props: Props):JSX.Element => {
  //console.log(kickbackChangesReport,reports)
  const tableElements: JSX.Element[] = [];
  Object.keys(props.reports).forEach((w,i) =>
    tableElements.push(
      <div style={{ marginBottom: 15, marginTop: 15 }}>
        <StyledAccordion
          alwaysOpen={true}
          //title={`${i18n.t("kickbackProject.contract")} ${w} `}
          title=""
        >
          <>
            <div
            className={`animate animate-pop delay-${i}`}
              style={{ display: "flex", flexWrap: "wrap", marginRight: "4%",flexDirection:'row-reverse',width:'100%' }}
            >
              {props.checkBoxMenu(w)}
              <p style={{fontSize:'1.25rem',fontWeight:500,paddingRight:'12px'}}>{i18n.t("kickbackProject.contract")} {w} </p>
            </div>

            <div style={{ maxHeight: "30vh" }}>
              <Datalist
                isLoading={props.loading}
                uniqueKey="contractId"
                items={props.reports[w]}
                columns={editColumns(props)}
                noItemsText={i18n.t("kickback.table.empty")}
                initialSortKey="contractId"
                setColumns={(cols) => {}}
              />
            </div>
            <Stack
              style={{ marginTop: 5, marginBottom: 5 }}
              direction="horizontal"
            >
              {/* {this.totalBilling(w)} */}
            </Stack>
          </>
        </StyledAccordion>
      </div>
    )
  );
 
 return <>
  <StyledAccordion title={/*"Bekräfta rapport"*/''} alwaysOpen>
        <div
          ref={props.sectionRef}
          className="datalist"
          style={{
            borderTop: "5px solid rgb(245, 156, 47)",
            borderBottom: "2px solid rgb(245, 156, 47)",
            marginBottom: "30px",
          
          }}
        >
          {tableElements.map(x => x)}
        </div>
    </StyledAccordion>    
  </>
};
// const renderCompressed = (props: Props):JSX.Element => {
//   const tableElements: JSX.Element[] = [];
//   // Object.keys(props.reports).forEach((w) =>
//   //   tableElements.push(
//   //     <div style={{ marginBottom: 15, marginTop: 15 }}>
//   //       <StyledAccordion
//   //         alwaysOpen={true}
//   //         title={`${i18n.t("kickbackProject.contract")} ${w} `}
//   //       >
//   //         <>
//   //           <div
//   //             style={{ display: "flex", flexWrap: "wrap", marginRight: "4%" }}
//   //           >
//   //             {/* {this.checkBoxMenu(w)} */}
              
//   //           </div>

//   //           <div style={{ maxHeight: "30vh" }}>
//   //             <Datalist
//   //               isLoading={props.loading}
//   //               uniqueKey="contractId"
//   //               items={props.reports[w]}
//   //               columns={columns}
//   //               noItemsText={i18n.t("kickback.table.empty")}
//   //               initialSortKey="contractId"
//   //               setColumns={(cols) => {}}
//   //             />
//   //           </div>
//   //           <Stack
//   //             style={{ marginTop: 5, marginBottom: 5 }}
//   //             direction="horizontal"
//   //           >
//   //             {/* {this.totalBilling(w)} */}
//   //           </Stack>
//   //         </>
//   //       </StyledAccordion>
//   //     </div>
//   //   )
//   // );
//   tableElements.push(compView({ ...props }));
//   return tableElements;
// };
const compView = ({
  sectionRef,
  kickbackChangesReport,
  renderKickbackChangesField,
  contractConfirmation,
  checkBoxMenu,
 // editModeToggleHandler
}: Props) => {
  if (typeof kickbackChangesReport !== "undefined") {
    //- ${this.props.contractDict[x.contractId].title} ${this.props.contractDict[x.contractId].name}
    
    return (
        
        <StyledAccordion title={''/*"Bekräfta rapport"*/} alwaysOpen>
        <div
          ref={sectionRef}
          className="datalist"
          style={{
            borderTop: "5px solid rgb(245, 156, 47)",
            borderBottom: "2px solid rgb(245, 156, 47)",
            marginBottom: "30px",
          }}
        >
          {/* <Form.Check className="customSwitch" type="switch" label="Aktivera radeditering" reverse onClick={editModeToggleHandler}/> */}
          <table className="datalistTable">
            <thead className="datalistTableHead">
              <tr>
                <th className="datalistTableHeader">Avtal</th>
                <th className="datalistTableHeader">
                  Totalt belopp exkl. moms
                </th>
                <th className="datalistTableHeader">Ev.ändringar</th>
                <th className="datalistTableHeader">Ev.ändringar Kommentar</th>
                <th className="datalistTableHeader">
                  Total kickback rapporterat
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(kickbackChangesReport).map((x, i) => {
                return (
                  <tr className="datalistTableRow datalistTableRowHover">
                    <td
                      className="datalistTableCell"
                      style={{ height: "100%" }}
                      aria-disabled
                    >{`${x.contractId} `}</td>
                    <td
                      className="datalistTableCell"
                      style={{ height: "100%" }}
                      aria-disabled
                    >
                      {" "}
                      {ReadFriendlyNumber(x.sumTotal)}
                    </td>
                    <td
                      className="datalistTableCell"
                      style={{
                        height: "100%",
                        border: "2px solid rgb(245, 156, 47,0.2)",
                      }}
                    >
                      {
                        /*x.changedSum*/ renderKickbackChangesField(
                          x,
                          "changedSum"
                        )
                      }
                    </td>
                    <td
                      className="datalistTableCell"
                      style={{
                        height: "100%",
                        border: "2px solid rgb(245, 156, 47,0.2)",
                      }}
                    >
                      {
                        /*x.changesComment*/ renderKickbackChangesField(
                          x,
                          "changesComment"
                        )
                      }
                    </td>
                    <td
                      className="datalistTableCell"
                      style={{ height: "100%" }}
                      aria-disabled
                    >
                      {ReadFriendlyNumber(x.totalKickbackReported)}
                    </td>
                    <td
                      className={`datalistTableCell ${
                        contractConfirmation[
                          i18n.t("kickbackProject.invoiceReport")
                        ]
                          ? `animate animate-pop delay-${i}`
                          : ""
                      }`}
                      style={{ height: "100%", width: 30, cursor: "pointer" }}
                    >
                      {checkBoxMenu(x.contractId)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="datalistTableFoot"></tfoot>
          </table>
        </div>
      </StyledAccordion>
    );
  } else {
    return <></>;
  }
};
